.tabulator {
  @apply border-gray-300 bg-gray-50 !important;
}

.tabulator-headers {
  @apply bg-gray-100  !important;
}

.tabulator-header {
  @apply border-gray-300  !important;
}

.tabulator-col-group-cols {
  @apply border-gray-300 !important;
}

.tabulator-col {
  @apply border-gray-300 !important;
}

.tabulator-col {
  @apply bg-gray-100 !important;
}
